import type { FC } from 'react';
import React from 'react';

import styled from 'styled-components';

import logo from './logo.png';
import logoBg from './logo_bg.png';
import { DiscordIcon, TelegramIcon, TwitterIcon } from '../Icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 580px;
  margin: 15px 15px 35px;

  text-align: center;
`;

const LogoBgWrapper = styled.div`
  position: relative;

  &::before {
    position: absolute;
    top: -78px;
    right: 0;
    left: 0;
    height: 216px;

    background: url(${logoBg}) no-repeat 50% 50%;
    background-size: 216px;

    content: '';
  }
`;

const Logo = styled.img`
  width: 274px;
  height: 82px;
  margin-bottom: 35px;
`;

const Title = styled.h1`
  max-width: 440px;
  margin: 0;

  color: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
`;

const SubTitle = styled.span`
  margin: 30px 0 13px;

  color: #a8a9ff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;

const Description = styled.span`
  color: #e7e5ff;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Button = styled.button`
  position: relative;
  z-index: 1;

  padding: 10px 25px;
  margin: 33px 0;

  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;

  background: linear-gradient(
    274.49deg,
    #0c9ff5 0%,
    #ba4db4 59.35%,
    #fe518d 99.33%
  );
  border: none;
  border-radius: 24px;

  appearance: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    &::before {
      position: absolute;
      top: -3px;
      right: -3px;
      bottom: -3px;
      left: -3px;
      z-index: -1;

      background: linear-gradient(
        274.49deg,
        #0c9ff5 0%,
        #ba4db4 59.35%,
        #fe518d 99.33%
      );
      border-radius: 24px;
      opacity: 0.5;

      content: '';
    }
  }
`;

const Socials = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  grid-gap: 22px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  grid-gap: 16px;

  color: #a8a9ff;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  &:hover {
    color: #fff;
  }
`;

interface Props {}

export const Main: FC<Props> = props => {
  return (
    <Wrapper>
      <LogoBgWrapper>
        <Logo src={logo} />
      </LogoBgWrapper>
      <Title>Spool.so is a non-custodial PLSA protocol on Solana</Title>
      <SubTitle>What is PLSA?</SubTitle>
      <Description>
        PLSA (Prize Linked Savings Account) – a prize linked savings account
        works as a lottery. A user deposits a certain amount of crypto into the
        protocol during the draw period. After the draw period ends, the
        protocol chooses a random user that receives the winnings earned by
        funds being used by lending protocols (such as Solend, etc.). Meanwhile,
        users’ funds are safe in the protocol and can be withdrawn at any time.
      </Description>
      <a
        href="https://drive.google.com/drive/folders/1Plw63d0HX8DcKStgibhpIKDtinWa78GC?usp=sharing"
        target="_blank"
      >
        <Button>Protocol presentation</Button>
      </a>
      <Socials>
        <Link href="https://github.com/spool-hq" target="_blank">
          <DiscordIcon />
          Github
        </Link>
        <Link href="https://discord.gg/STWyK43KJM" target="_blank">
          <DiscordIcon />
          Discord
        </Link>
        <Link href="https://t.me/spool_so" target="_blank">
          <TelegramIcon />
          Telegram
        </Link>
        <Link href="https://twitter.com/spool_so" target="_blank">
          <TwitterIcon />
          Twitter
        </Link>
      </Socials>
    </Wrapper>
  );
};
