import React from 'react';

import { Main } from './components/Main';

import GlobalStyles from './styles/Global';

function App() {
  return (
    <>
      <GlobalStyles />
      <Main />
    </>
  );
}

export default App;
