import { FC } from 'react';

export const TwitterIcon: FC = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.592 1.6157C17.3093 1.73484 17.0185 1.8354 16.7215 1.91704C17.0732 1.53919 17.3413 1.0946 17.505 0.608086C17.5417 0.499035 17.5037 0.379668 17.4093 0.308346C17.3151 0.236968 17.1841 0.228419 17.0804 0.286833C16.4495 0.642286 15.7689 0.897733 15.0552 1.04711C14.3363 0.379778 13.3599 0 12.3505 0C10.2199 0 8.4864 1.6467 8.4864 3.67075C8.4864 3.83016 8.49703 3.98869 8.51805 4.14502C5.87409 3.92449 3.41605 2.68994 1.72812 0.722985C1.66797 0.652876 1.5753 0.615092 1.48042 0.622318C1.38549 0.629378 1.30019 0.680236 1.25223 0.758398C0.90988 1.31645 0.728894 1.95471 0.728894 2.60411C0.728894 3.48861 1.06131 4.32782 1.64852 4.98356C1.46997 4.92482 1.2967 4.8514 1.13134 4.76419C1.04256 4.71725 0.934209 4.71797 0.84601 4.76601C0.757752 4.81405 0.702417 4.90242 0.700094 4.99901C0.699688 5.01528 0.699687 5.03155 0.699687 5.04804C0.699687 6.3683 1.44767 7.55695 2.59125 8.20481C2.493 8.19549 2.39482 8.18197 2.29727 8.16427C2.1967 8.14601 2.09329 8.17949 2.02547 8.25236C1.95753 8.32517 1.93506 8.42666 1.96636 8.51933C2.38965 9.77478 3.47946 10.6982 4.79694 10.9798C3.70423 11.6299 2.45462 11.9705 1.14283 11.9705C0.869119 11.9705 0.593836 11.9552 0.324418 11.9249C0.190579 11.9098 0.0626057 11.9849 0.0170252 12.1058C-0.0285553 12.2268 0.0197542 12.3616 0.133618 12.431C1.81882 13.4574 3.7674 14 5.76859 14C9.70268 14 12.1637 12.2376 13.5355 10.7592C15.2461 8.91566 16.2271 6.47554 16.2271 4.06454C16.2271 3.96381 16.2255 3.8621 16.2223 3.76071C16.8971 3.27768 17.4782 2.69309 17.951 2.02118C18.0228 1.91913 18.0151 1.78427 17.9318 1.69033C17.8488 1.59634 17.7103 1.56594 17.592 1.6157Z"
        fill="currentColor"
      />
    </svg>
  );
};
