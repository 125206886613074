import { FC } from 'react';

export const TelegramIcon: FC = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.282608 6.71295L3.96933 8.0958L5.39632 12.7078C5.48763 13.0032 5.84704 13.1124 6.0856 12.9164L8.14064 11.2327C8.35606 11.0563 8.66288 11.0475 8.88801 11.2118L12.5946 13.9162C12.8498 14.1026 13.2113 13.9621 13.2753 13.6522L15.9906 0.526311C16.0604 0.187781 15.7295 -0.0946306 15.4087 0.0300401L0.278288 5.89594C-0.0950996 6.04066 -0.0918463 6.57193 0.282608 6.71295ZM5.16635 7.35967L12.3716 2.89988C12.5011 2.81996 12.6343 2.99593 12.5231 3.09959L6.5767 8.65456C6.36768 8.85009 6.23286 9.11176 6.19467 9.39578L5.99211 10.9044C5.96528 11.1058 5.68373 11.1258 5.62843 10.9309L4.84939 8.17989C4.76016 7.86613 4.89019 7.53097 5.16635 7.35967Z"
        fill="currentColor"
      />
    </svg>
  );
};
