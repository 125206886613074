import { FC } from 'react';

export const DiscordIcon: FC = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75229 1.1725C3.91746 0.62475 5.16332 0.22667 6.46583 0C6.62579 0.290583 6.81267 0.681424 6.94152 0.992336C8.32613 0.783119 9.69799 0.783119 11.0571 0.992336C11.186 0.681424 11.3771 0.290583 11.5385 0C12.8424 0.22667 14.0897 0.626212 15.2549 1.1754C17.605 4.74369 18.2421 8.22334 17.9236 11.6536C16.3648 12.8232 14.8542 13.5336 13.3691 13.9986C13.0024 13.4915 12.6753 12.9525 12.3936 12.3844C12.9302 12.1795 13.4441 11.9267 13.9297 11.6333C13.8009 11.5374 13.6749 11.4371 13.5531 11.334C10.5914 12.7258 7.37333 12.7258 4.44695 11.334C4.32377 11.4371 4.19779 11.5374 4.07037 11.6333C4.55739 11.9282 5.07271 12.181 5.60928 12.3859C5.32755 12.9525 5.00192 13.493 4.63383 14C3.14727 13.5351 1.63526 12.8246 0.0765038 11.6536C-0.297264 7.67706 0.715002 4.22936 2.75229 1.1725ZM11.9901 9.54402C12.8792 9.54402 13.6083 8.71005 13.6083 7.69449C13.6083 6.67892 12.8948 5.84352 11.9901 5.84352C11.0854 5.84352 10.3563 6.67746 10.3719 7.69449C10.3705 8.71005 11.0854 9.54402 11.9901 9.54402ZM6.00994 9.54402C6.89903 9.54402 7.62816 8.71005 7.62816 7.69449C7.62816 6.67892 6.91463 5.84352 6.00994 5.84352C5.10528 5.84352 4.37616 6.67746 4.39173 7.69449C4.39173 8.71005 5.10528 9.54402 6.00994 9.54402Z"
        fill="currentColor"
      />
    </svg>
  );
};
