import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    *{
        box-sizing: border-box;
    }
    
    html {
      height: 100%;
    }
    
    body{
      min-height: 100%;

      font-family: 'Inter', sans-serif;
      -webkit-font-smoothing: antialiased;
    }
    
    #root {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100vh;

      background: linear-gradient(246.2deg, #101051 0.24%, #43186D 100%);
    }

    a {
      text-decoration: none;
      
      transition: color 0.3s;
    }

    svg {
      fill: currentColor;
    }
`;

export default GlobalStyles;
